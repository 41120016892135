import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const CallToAction = ({
  title,
  description,
  buttonText,
  buttonLink,
  bgColor = "bg-[#5cb464]",
  textColor = "text-white",
  buttonBgColor = "bg-white",
  buttonTextColor = "text-[#5cb464]",
}) => {
  return (
    <motion.div
      className={`${bgColor} ${textColor} py-12 px-6 text-center sm:bg-fixed bg-scroll`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <motion.h2
        className="text-4xl font-bold mb-4"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.2 }}
      >
        {title}
      </motion.h2>
      <motion.p
        className="text-2xl mb-6"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.4 }}
      >
        {description}
      </motion.p>
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1, delay: 0.6 }}
      >
        <Link
          to={buttonLink}
          className={`${buttonBgColor} ${buttonTextColor} font-semibold py-3 px-6 rounded-full shadow-md hover:bg-gray-100 transition duration-300 ease-in-out`}
        >
          {buttonText}
        </Link>
      </motion.div>
    </motion.div>
  );
};

export default CallToAction;
