import React from "react";
import { motion } from "framer-motion";
import Button from "./ui/Button";
import Header from "./ui/Header";

const Section = ({
  headerFirstText,
  headerSecondText,
  content,
  buttonText,
  imageSrc,
  imageAlt,
  reverseColumns = false,
}) => {
  return (
    <motion.div
      className={`row flex flex-col items-center max-w-screen-xl mx-auto md:flex-row sm:px-6 p-6 ${
        reverseColumns ? "md:flex-row-reverse" : ""
      }`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <motion.div
        className="flex items-center py-5 md:w-1/2 md:pb-20 md:pt-10 md:px-10"
        initial={{ opacity: 0, x: reverseColumns ? 50 : -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
      >
        <div className="text-left">
          <Header firstText={headerFirstText} secondText={headerSecondText} />
          <p className="max-w-lg mb-6 font-light text-gray-500 lg:mb-8 md:text-md lg:text-xl">
            {content}
          </p>
          {buttonText && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.5 }}
              className="mt-5 sm:flex md:mt-8"
            >
              <a href="/">
                <Button title={buttonText} />
              </a>
            </motion.div>
          )}
        </div>
      </motion.div>
      <motion.div
        className="flex items-center justify-center py-5 md:w-1/2 md:pb-20 md:pt-10 md:px-10"
        initial={{ opacity: 0, x: reverseColumns ? -50 : 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
      >
        <div className="relative w-full p-3 rounded md:p-8">
          <motion.div
            className="rounded-lg bg-white text-black w-full"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ duration: 1, delay: 0.3 }}
          >
            <img loading="lazy" src={imageSrc} alt={imageAlt} />
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Section;
