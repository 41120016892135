import React from "react";
import bgimage from "../assets/dogwalkingbanner.jpg";

const TopBanner = ({ title, subtitle }) => {
  return (
    <div className="relative">
      <div
        className="bg-cover bg-center h-[30vh] sm:bg-fixed bg-scroll parallax-bg"
        style={{ backgroundImage: `url(${bgimage})` }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-70"></div>
        <div className="relative z-10 text-center text-white h-full flex flex-col justify-center items-center">
          <h1 className="text-4xl font-bold mb-4">{title}</h1>
          <p className="text-2xl mb-6">{subtitle}</p>
        </div>
      </div>
    </div>
  );
};

export default TopBanner;
