import React from "react";
import { motion } from "framer-motion";

const IconCard = ({ icon: Icon, title, description }) => {
  return (
    <motion.div
      className="relative w-full text-center max-md:max-w-sm max-md:mx-auto group md:w-2/5 lg:w-1/4"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
    >
      <motion.div
        className="bg-[#dbaf88] rounded-full flex justify-center items-center mb-5 w-20 h-20 mx-auto transition-all duration-300 group-hover:bg-[#c4946a]"
        whileHover={{ scale: 1.1 }}
        transition={{ type: "spring", stiffness: 300 }}
      >
        <Icon className="text-white text-4xl" />
      </motion.div>
      <h4 className="text-lg font-medium text-gray-900 mb-3 capitalize">
        {title}
      </h4>
      <p className="font-light text-gray-500 lg:mb-8 md:text-sm lg:text-md">
        {description}
      </p>
    </motion.div>
  );
};

export default IconCard;
